import React from 'react';
import { Document, Page, Text, View, StyleSheet, Image, Font } from '@react-pdf/renderer';
import { URL } from "../constants";
import DejaVuSans from '../../fonts/DejaVuSans.ttf';
import DejaVuSansBold from '../../fonts/DejaVuSans-Bold.ttf';

// Register fonts
Font.register({ family: 'DejaVuSans', src: DejaVuSans });
Font.register({ family: 'DejaVuSans-Bold', src: DejaVuSansBold });

// Create styles
const styles = StyleSheet.create({
  container: {
    padding: 20,
  },
  table: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderRightWidth: 0,
    borderLeftWidth: 0,
    borderBottomWidth: 0,
  },
  table1: {
    display: "table",
    width: "auto",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderRightWidth: 0,
    borderBottomWidth: 0,
    marginTop:10
  },
  tableRow: {
    flexDirection: "row",
  },
  tableColHeader: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 1,
    borderTopWidth: 0,
    textAlign: "center",
    padding: 5,
    fontSize: 11,
    fontFamily:'DejaVuSans-Bold',
    textDecoration: "underline",
    textTransform: 'uppercase'
  },
  tableColHeader1: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 1,
    borderTopWidth: 0,
    textAlign: "center",
    padding: 5,
    fontSize: 10,
    fontFamily:'DejaVuSans-Bold',
    textDecoration: "underline",
    textTransform: 'uppercase'
  },
  tableCol: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans',
    textTransform: 'uppercase'
  },
  tableColb: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans-Bold',
    textTransform: 'uppercase'
  },
  tableColbl: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 1,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans-Bold',
    textTransform: 'uppercase'
  },
  tableColblotlong: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 1,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans-Bold',
    textTransform: 'uppercase'
  },
  tableColmap: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 9,
    fontFamily:'DejaVuSans',
    textTransform: 'uppercase'
  },
  tableColmapl: {
    width: "20%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 1,
    borderRightWidth:0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 9,
    fontFamily:'DejaVuSans',
    textTransform: 'uppercase'
  },
  tableColim: {
    width: "50%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 1,
    // borderTopWidth: 0,
    padding: 5,
    fontSize: 9,
    textAlign: 'center',
    paddingLeft: 60,
    fontFamily:'DejaVuSans',
    textTransform: 'uppercase'
  },
  tableColrh: {
    width: "25%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 1,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 9,
    fontFamily:'DejaVuSans-Bold',
  },
  tableCollatlong: {
    width: "75%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans',
    wordBreak: 'break-word', 
    overflowWrap: 'break-word',
    textTransform: 'uppercase'
  },
  tableColr: {
    width: "100%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 1,
    borderTopWidth: 1,
    padding: 5,
    fontSize: 9,
    textAlign: 'center',
    fontFamily:'DejaVuSans-Bold',
    textTransform: 'uppercase'
  },
  tableColrr: {
    width: "75%",
    borderStyle: "solid",
    borderWidth: 1,
    borderColor: "#000000",
    borderLeftWidth: 0,
    borderTopWidth: 0,
    padding: 5,
    fontSize: 8,
    fontFamily:'DejaVuSans',
    textTransform: 'uppercase'
  },
  signatory: {
    marginTop: 10,
    textAlign: 'right',
    alignContent: 'flex-end',
    alignSelf: 'flex-end',
    marginLeft:'70%'
   
  },
  image: {
    width: 70,
    height: 70,
    marginVertical:5,
    textAlign:'center',
    marginLeft:30
  },
  simage: {
    width: 160,
    height: 180,
    marginVertical: 10,
    textAlign: 'center',
  },
  imageRow: {
    flexDirection: 'row',
    flexWrap: 'wrap',
    justifyContent: 'space-between',
    borderLeftWidth:0,
    borderStyle: "solid",
  },
  mapImage: {
    width: "60%",
    height: 150,
    borderRightWidth:1,
    borderBottomWidth:1,
    borderStyle: "solid",
  },
  mapContainer: {
    flexDirection:'row',
    position: 'absolute',
    bottom: 15,
    left: 60,
    width: 40,
    height: 40,
    border: 1,
    overflow: 'hidden',
    borderColor:'gray'
  },
  map: {
    width: 60,
    height: 60,
    objectFit: 'cover'
  },
  mapContainer1: {
    textAlign:'left',
    flexDirection: 'column',
    position: 'absolute',
    bottom: 15,
    right: 56,
    width: 120,
    height: 40,
    borderWidth: 1, // Changed 'border' to 'borderWidth'
    overflow: 'hidden',
    backgroundColor: 'rgba(0, 0, 0, 0.4)', // Set black background with 40% opacity
    borderColor: 'gray',
    padding: 3,
  },
  mapAddressText: {
    fontSize: 5,
    color: 'white',
    textTransform: 'uppercase'
  },
  sb:{
    
      width: "100%",
      borderStyle: "solid",
      borderWidth: 1,
      borderColor: "#000000",
     
      // borderTopWidth: 0,
      padding: 5,
     
     
  
  }
});

function formatDate(dateString) {
  const date = new Date(dateString);
  const day = String(date.getDate()).padStart(2, '0');
  const month = String(date.getMonth() + 1).padStart(2, '0'); // Months are zero-based
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
}

const PdfContent_Residence = ({ data }) => (
  <Document>
    <Page style={styles.container}>
      <View style={styles.table}>
        <>
          <View style={styles.tableRow}>
          <Text style={styles.tableColHeader}>{data.company_name}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableColHeader1}>Residence Verification Report</Text>
          </View>
        </>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Product Type</Text>
          <Text style={styles.tableCol}>{data.product_type}</Text>
          <Text style={styles.tableColb}>Case Number</Text>
          <Text style={styles.tableColb}>{data.case_id}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Applicant Name</Text>
          <Text style={styles.tableCol}>{data.applicant_name}</Text>
          <Text style={styles.tableColb}>Applicant Phone No.</Text>
          <Text style={styles.tableCol}>{data.applicant_number}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Address</Text>
          <Text style={styles.tableCol}>{data.address}</Text>
          <Text style={styles.tableColb}>Landmark</Text>
          <Text style={styles.tableCol}>{data.landmark}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Case Allocation Date</Text>
          <Text style={styles.tableCol}>{formatDate(data.i_date) + " "+ data.i_time}</Text>
          <Text style={styles.tableColb}>Client Name</Text>
          <Text style={styles.tableCol}>{data.client_name}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Visited At</Text>
          <Text style={styles.tableCol}>{formatDate(data.c_date) + " " + data.c_time}</Text>
          <Text style={styles.tableColb}>Submitted At</Text>
          <Text style={styles.tableCol}>{data.audit_date + " " + data.audit_time}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Overall Status</Text>
          <Text style={styles.tableColb}>{data.overallstatus}</Text>
          <Text style={styles.tableColb}>Reason for Negative FI (If Any)</Text>
          <Text style={styles.tableCol}>{data.reasonfornegativefi}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColrh}>Remarks</Text>
          <Text style={styles.tableColrr}>{data.remarks}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColr}>
            THE FOLLOWING INFORMATION IS TO BE OBTAINED FROM APPLICANT(S) OR ANYBODY RESIDING AT THE ADDRESS:
          </Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Person Met</Text>
          <Text style={styles.tableCol}>{data.name_of_person_met}</Text>
          <Text style={styles.tableColb}>Relationship with Applicant</Text>
          <Text style={styles.tableCol}>{data.relation_with_applicant}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Applicant's Age</Text>
          <Text style={styles.tableCol}>{data.applicant_age}</Text>
          <Text style={styles.tableColb}>Educational Qualification</Text>
          <Text style={styles.tableCol}>{data.educational_qualification}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Staying Since</Text>
          <Text style={styles.tableCol}>{data.time_of_stay_current_address}</Text>
          <Text style={styles.tableColb}>Address Proof Details</Text>
          <Text style={styles.tableCol}>{data.address_proof_details}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColr}>DETAILS OF FAMILY MEMBER(S):</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Total Family Members</Text>
          <Text style={styles.tableCol}>{data.total_family_members}</Text>
          <Text style={styles.tableColb}>Working Family Members</Text>
          <Text style={styles.tableCol}>{data.earning_members}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Dependent (Adults) Member</Text>
          <Text style={styles.tableCol}>{data.dependent_members}</Text>
          <Text style={styles.tableColb}>Dependent (Children) Member</Text>
          <Text style={styles.tableCol}>{data.dependent_members_child}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Retired / Pensioner Member</Text>
          <Text style={styles.tableCol}>{data.retired_pensioner_member}</Text>
          <Text style={styles.tableColb}>Applicant's Marital Status</Text>
          <Text style={styles.tableCol}>{data.marital_status}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Is Spouse Earning?</Text>
          <Text style={styles.tableCol}>{data.spouse_earning}</Text>
          <Text style={styles.tableColb}>Spouse Employment Details</Text>
          <Text style={styles.tableCol}>{data.spouse_employment_details}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColr}>HOUSE DETAILS:</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Does applicant stay at this Address?</Text>
          <Text style={styles.tableCol}>{data.applicant_stay_at_address}</Text>
          <Text style={styles.tableColb}>Type Of Residence</Text>
          <Text style={styles.tableCol}>{data.type_of_accommodation}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Type of House</Text>
          <Text style={styles.tableCol}>{data.type_of_house}</Text>
          <Text style={styles.tableColb}>House Condition</Text>
          <Text style={styles.tableCol}>{data.house_condition}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Exterior</Text>
          <Text style={styles.tableCol}>{data.exterior}</Text>
          <Text style={styles.tableColb}>Standard of Living</Text>
          <Text style={styles.tableCol}>{data.standard_of_living}</Text>
        </View>
        <View style={styles.tableRow}>
          <Text style={styles.tableColbl}>Type of Locality</Text>
          <Text style={styles.tableCol}>{data.type_of_locality}</Text>
        </View>
        <View wrap={false}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColr}>THE FOLLOWING IS BASED ON VERIFIER OBSERVATION:</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableColbl}>Neighbour Name 1</Text>
            <Text style={styles.tableCol}>{data.neighbour_name_1}</Text>
            <Text style={styles.tableColb}>Address 1</Text>
            <Text style={styles.tableCol}>{data.address_1}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableColbl}>Neighbour Name 2</Text>
            <Text style={styles.tableCol}>{data.neighbour_name_2}</Text>
            <Text style={styles.tableColb}>Address 2</Text>
            <Text style={styles.tableCol}>{data.address_2}</Text>
          </View>
          <View style={styles.tableRow} wrap={false}>
            <Text style={styles.tableColbl}>Neighbour Feedback</Text>
            <Text style={styles.tableCol}>{data.neighbour_feedback}</Text>
            <Text style={styles.tableColb}>Ease To Locate</Text>
            <Text style={styles.tableCol}>{data.ease_to_locate}</Text>
          </View>
        </View>
        <View wrap={false}>
          <View style={styles.tableRow} >
            <Text style={styles.tableColr}>OTHER OBSERVATION:</Text>
          </View>
          <View style={styles.tableRow} wrap={false}>
            <Text style={styles.tableColbl}>Vehicle Seen</Text>
            <Text style={styles.tableCol}>{data.vehicle_seen}</Text>
            <Text style={styles.tableColb}>Loan Amount</Text>
            <Text style={styles.tableCol}>{data.loanamount}</Text>
            <Text style={styles.tableColbl}>Political Link</Text>
            <Text style={styles.tableCol}>{data.political_link}</Text>
          </View>
        </View>
        <View wrap={false}>
          <View style={styles.tableRow} wrap={false}>
            <Text style={styles.tableColr}>MAP LOCATION</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableColblotlong}>Lat-Long</Text>
            <Text style={styles.tableCollatlong}>{data.latitude + '-'+ data.longitude}</Text>
            <Text style={styles.tableColb}>Field Agent Name</Text>
            <Text style={styles.tableCol}>{data.field_agent_name}</Text>
          </View>
          <View style={styles.tableRow}>
            <Text style={styles.tableColmapl}></Text>
            <Image
              style={styles.mapImage}
              src={`https://maps.googleapis.com/maps/api/staticmap?center=${data.latitude},${data.longitude}&zoom=15&size=600x300&maptype=roadmap&markers=color:red%7C${data.latitude},${data.longitude}&key=AIzaSyDnUfRzHWPaBBgG9n3byhr3yY2MGih8Q0E`}
            />
            <Text style={styles.tableColmap}></Text>
          </View>
        </View>
        <View wrap={false}>
          <View style={styles.tableRow}>
            <Text style={styles.tableColr}>SNAPS TAKEN FROM VERIFICATION POINT:</Text>
          </View>
        </View>
        <View style={styles.imageRow}>
  {data.files_name && data.files_name.map((im, index) => (
    <View key={index} style={styles.tableColim}>
      <Image style={styles.simage} src={`${im.imageUrl}`} />
      <View style={styles.mapContainer}>
        <Image
          style={styles.map}
          src={`https://maps.googleapis.com/maps/api/staticmap?center=${im.latitude},${im.longitude}&zoom=13&size=200x200&maptype=roadmap&markers=color:red%7C${im.latitude},${im.longitude}&key=AIzaSyDnUfRzHWPaBBgG9n3byhr3yY2MGih8Q0E`}
          alt={`map-${index}`}
        />
      </View>
      <View style={styles.mapContainer1}>
        <Text style={styles.mapAddressText}>
          {data.mapaddress[im.filepath].city}, {data.mapaddress[im.filepath].district}
        </Text>
        <Text style={styles.mapAddressText}>
          {data.mapaddress[im.filepath].formattedAddress}
        </Text>
        <Text style={styles.mapAddressText}>
          lat: {im.latitude}
        </Text>
        <Text style={styles.mapAddressText}>
          long: {im.longitude}
        </Text>
        <Text style={styles.mapAddressText}>
          {im.c_time || 'null'}
        </Text>
      </View>
    </View>
  ))}

  {/* Check if the number of items is odd and add an empty column */}
  {data.files_name && data.files_name.length % 2 !== 0 && (
    <View style={styles.tableColim}></View> // Empty column to complete the row
  )}

  {/* Signatory Section */}
  <View style={styles.sb}>
    <View style={styles.signatory}>
      <Text style={{ fontSize: 10, paddingRight: 10, marginBottom: -5, textTransform: 'uppercase' }}>For {data.company_name}</Text>
      {data.company_name !== "SYNERGY ENTERPRISES" ? (
        <Image style={styles.image} src={`${URL}/uploads/stamp.png`} />
      ) : (
        <Image style={styles.image} src={`${URL}/uploads/stamp1.png`} />
      )}
      <Text style={{ fontSize: 10, paddingBottom: 10, textTransform: 'uppercase', marginLeft: 5 }}>Authorized Signatory</Text>
    </View>
  </View>
</View>

        {/* <View wrap={false}>
         
        </View> */}
      </View>
    </Page>
  </Document>
);

export default PdfContent_Residence;
